"use strict";
/*
Pour tester la validation serveur, tapper le code ci dessous dans la console :
window.addEventListener('submit', function(event) {
    event.stopImmediatePropagation();
}, true);
 */

var myform = document.querySelector('#frmContact form');

if (myform) {
  // validate form on submission
  var validateForm = function validateForm(e) {
    var form = e.target,
        field = Array.from(form.elements);
    var firstElement = true,
        mixerror = false; // apply/remove invalid class

    field.forEach(function (i) {
      if (i.closest(".form-group") && i.closest(".form-group") != "null") {
        if (i.checkValidity()) {
          // field is valid - remove class
          i.closest(".form-group").classList.remove('invalid');
        } else {
          // field is invalid - add class
          i.closest(".form-group").classList.add('invalid');

          if (firstElement) {
            i.focus();
            firstElement = false;
          }
        }
      } //on teste les erreurs mixant différents champs


      if (document.getElementById("rdReponse-telephone").checked && document.getElementById("SelHoraireRdv").value == "") {
        mixerror = true;
        document.getElementById("errorHeureRappel").classList.add("invalid"); //si c'est la seulle erreur on mets le focus sur ce champ

        if (form.checkValidity()) document.getElementById("errorHeureRappel").focus();
      } else document.getElementById("errorHeureRappel").classList.remove("invalid");
    });

    if (!form.checkValidity() || mixerror) {
      // form is invalid - cancel submit
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  };

  myform.noValidate = true; // validate form on submit

  myform.addEventListener('submit', validateForm);
  ;
}